var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-records"},[_c('div',{staticClass:"c-records__header"},[(_vm.Title !== '')?_c('h2',{staticClass:"c-records__title"},[_vm._v(_vm._s(_vm.Title)+" ("+_vm._s(_vm.PagerSorter.Total)+")")]):_vm._e(),_c('div',{staticClass:"c-records__subtitle"},[_vm._t("Subtitle")],2),(_vm.MultiCreateAction)?_c('div',{staticClass:"c2-record__actions"},[(_vm.CreateActions.length > 0)?_c('Popup',{attrs:{"Alignment":"right"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var Shown = ref.Shown;
return [_c('button',{staticClass:"\n                            c-button\n                            c-button--secondary\n                            c-button--dropdown\n                            c2-record__show-actions\n                        ",class:{ 'c-button--dropdown-open': Shown },attrs:{"disabled":false}},[_vm._v(_vm._s(_vm.CreateLabel))])]}},{key:"body",fn:function(){return [_c('div',{staticClass:"c-list"},[_vm._l((_vm.CreateActions),function(group,groupIndex){return _vm._l((group),function(action,index){return _c('li',{key:action.Id,staticClass:"c-list__item",class:{
                                    'c-list__item--underlined':
                                        ( index === ( group.length - 1 ) ) &&
                                        ( groupIndex < _vm.Actions.length - 1 ),

                                    'c-list__item--disabled':
                                        action.Enabled !== undefined && !action.Enabled()
                                },on:{"click":function($event){return _vm.$emit( 'Action', action )}}},[_c('div',{staticClass:"c-list__item-content"},[_c('span',[_vm._v(_vm._s(action.Title))])])])})})],2)]},proxy:true}],null,false,1256198673)}):_vm._e()],1):(_vm.CreateLabel !== '' && !_vm.MultiCreateAction)?_c('button',{staticClass:"c-button",on:{"click":function($event){return _vm.$emit( 'Create' )}}},[_vm._v("\n            "+_vm._s(_vm.CreateLabel)+"\n        ")]):_vm._e()]),_c('div',{staticClass:"c-records__description"},[_vm._t("Description")],2),(_vm.loading === false && _vm.Rows.length === 0 && _vm.appliedFilters.length === 0)?_c('div',{staticClass:"c-records__splash"},[_vm._t("Splash")],2):_c('div',[_c('div',{staticClass:"c-records__quick-filters"},_vm._l((_vm.QuickFilters),function(filter,index){return _c('button',{key:index,staticClass:"c-records__quick-filter",class:{
                    'c-records__quick-filter--is-applied': _vm.appliedQuickFilter === filter
                },on:{"click":function($event){return _vm.applyQuickFilter( filter )}}},[_vm._v("\n                "+_vm._s(filter.Name)+"\n            ")])}),0),_c('div',{staticClass:"c-records__table-header"},[(_vm.MultiFilter)?_c('MultiFilter',{staticClass:"c-records__filters",attrs:{"GetFilters":_vm.GetFilters,"ModelId":_vm.Id},on:{"Applied":_vm.applyFilters}}):_vm._e(),(_vm.Actions.length > 0 || _vm.MassActions.length > 0)?_c('TableActions',{class:{ 'table-actions--standalone': !_vm.MultiFilter },attrs:{"Rows":_vm.selectedRows,"TotalRows":_vm.Rows.length,"Label":_vm.Label,"PluralLabel":_vm.PluralLabel,"Actions":_vm.Actions,"MassActions":_vm.MassActions,"IsActionAllowed":_vm.IsActionAllowed},on:{"Clear":function () { return _vm.$refs.Table.SelectAll( false ); },"Action":_vm.execute}}):_vm._e()],1),_c('Table',{ref:"Table",staticClass:"c-records__table",attrs:{"Label":_vm.Label,"PluralLabel":_vm.PluralLabel,"Columns":_vm.Columns,"Rows":_vm.Rows,"Loading":_vm.loading,"Pagination":_vm.Pagination,"PageLimits":_vm.PageLimits,"PagerSorter":_vm.PagerSorter,"ActionsEnabled":_vm.Actions.length + _vm.MassActions.length > 0,"MassActions":_vm.MassActions,"IsSelectable":_vm.IsSelectable,"IsActionAllowed":_vm.IsActionAllowed,"ColumnConfig":_vm.ColumnConfig},on:{"PagerSorter":_vm.update,"ToggleColumn":function ( column ) { return _vm.$emit( 'ToggleColumn', column ); },"RowAction":_vm.execute,"ToggleSorter":_vm.update,"PageLimit":_vm.update,"SelectRows":function ( rows ) { return _vm.selectedRows = rows; }},scopedSlots:_vm._u([{key:"Cell",fn:function(ref){
                var Row = ref.Row;
                var Column = ref.Column;
                var Value = ref.Value;
return [_vm._t('Cell(' + Column.Id + ')',null,{"Row":Row,"Column":Column,"Value":Value}),_vm._t("Cell",null,{"Row":Row,"Column":Column,"Value":Value})]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }