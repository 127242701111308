


import { Component, Vue } from "vue-property-decorator";

import { Filter } from "@Internal/Contracts";
import { AdsPublications } from "App";
import { EventBus, $t } from "App/IOC";
import { MassActions, RecordActions } from "Web/Contracts";
import RecordsComponent from "Web/Components/Records.vue";
import FtpForm from "./Form.vue";
import {
    Column,
    AdsPublication,
    PagerSorter
} from "App/Models";
import { Notify } from "@/Web/Libs/Misc";

@Component({
    components: {
        Records: RecordsComponent,
        FtpForm
    }
})
export default class AdsPublicationsView extends Vue {
    $refs!: Vue["$refs"] & {
        Records: RecordsComponent,
    };

    private columns: Column[] = [];
    private records: AdsPublication[] = [];
    private row: any = null;

    private pagerSorter: PagerSorter = PagerSorter.ForColumn(
        AdsPublications.Columns.GetDefaultSort(),
        undefined,
        true
    );

    private filters = AdsPublications.Filters.Get;

    private massActions: MassActions[] = [
        {
            Id: "Actions",
            Name: $t("GLOBAL_actions"),
            ActionGroups: [
                [
                    {
                        Id: "edit",
                        ShortName: "edit",
                        Name: $t("GLOBAL_actionUpdate"),
                        Multi: false
                    },
                    {
                        Id: "delete",
                        ShortName: "delete",
                        Name: $t("GLOBAL_actionDelete"),
                        Multi: false
                    }
                ]
            ]
        }
    ];

    private createActions: RecordActions<AdsPublication> = [
        [
            {
                Id: "ftp",
                Title: $t( "CUSTOMERS_actionFtp" ),
                Icon: "group"
            },
            {
                Id: "api",
                Title: $t( "CUSTOMERS_actionApi" ),
                Icon: "group"
            }
        ]
    ];

    private showForm: boolean = false;

    private async created(): Promise<void> {
        const [columns, error] = await AdsPublications.Columns.Get();

        if(error !== undefined) {
            EventBus.Publish("Error", error);
            return;
        }

        this.columns = columns;
    }

    private async update(
        filters: Filter[],
        pagerSorter: PagerSorter,
        resetPagination?: boolean
    ): Promise<void> {
        await this.$nextTick();
        this.$refs.Records.Loading();

        if(resetPagination)
            this.pagerSorter.Page = 1;

        const [records, updatedPagerSorter, error] = 
            await AdsPublications.Find(filters, pagerSorter);
        this.records = records as AdsPublication[];
        this.pagerSorter = updatedPagerSorter;

        if(error !== undefined) {
            EventBus.Publish("Error", error);
            return;
        }
    }

    private toggleColumn(column: Column) {
        AdsPublications.Columns.Toggle(column);
    }

    private executeAction(id: string, rows: AdsPublication[]): void {

        switch(id) {
            case "edit":
                this.row = rows[0];
                this.showForm = true;
                break;
            case "delete":
                EventBus.Publish("Confirmation", {
                    Props: {
                        Message: this.$tc("ADS_PUBLICATION_deleteConfirm"),

                        Actions: [{
                            Id: "endTrial",
                            Name: this.$t("GLOBAL_actionDelete"),
                            Active: true
                        }]
                    },
                    Events: {
                        Action: () => {
                            Notify(
                                undefined,
                                "Missing Backend",
                                "Missing Backend"
                            )
                        }
                    }
                });

                break;

            default:
                EventBus.Publish("UnderConstruction");
                break;
        }
    }

    private executeCreateAction(action: any): void {
        if(action.Id === "ftp") {
            this.showForm = true;
        } else {
            Notify(
                undefined,
                "Missing Backend",
                "Missing Backend"
            )
        }
    }

    private refresh(): void {
        this.closeModal();
        this.update([], this.pagerSorter, true);
    }

    private closeModal(): void {
        this.showForm = false;
        this.row = null;
    }

    private get id(): string {
        return "AdsPublications";
    }
}
