/* © IBS Group. See LICENSE file for full copyright & licensing details. */

import { CustomNotification } from "App/Models";
import { CustomError } from "App/Models";
import { EventBus } from "App/IOC";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function DetectOverflow( target: HTMLElement ) {
    const coordinates = target.getBoundingClientRect();

    let calculatedHeight: number = 0;

    if( coordinates.height ) {
        calculatedHeight = coordinates.height;

    } else {
        const children = target.children;

        for( const child of children )
            calculatedHeight += child.clientHeight;
    }

    return {
        bottom: coordinates.top + calculatedHeight > window.innerHeight,
        /* 68px refers to the navigation bar's height */
        top: coordinates.top < document.body.scrollTop,
        right: coordinates.right > window.innerWidth,
        left: coordinates.left < 0
    };
}

/* Move in a number list and reset when extremities are reached. */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function MovePointer( pointer: number, listLength: number, moves: number ) {
    if( pointer === 0 && moves < 0 ) {
        return listLength - 1;
    } else if( pointer === listLength - 1 && moves > 0 ) {
        return 0;
    } else {
        return pointer + moves;
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function Notify(
    error: CustomError | undefined,
    title: string,
    message: string
) {
    if( error !== undefined )
        EventBus.Publish(
            "Notification",
            CustomNotification.Failure( title, error.Message ) );
    else
        EventBus.Publish(
            "Notification",
            CustomNotification.Success( title, message )
        );
}

export {
    DetectOverflow,
    Notify,
    MovePointer
};
