/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import Router, { RawLocation, Route } from "vue-router";
import Vue from "vue";

import { $t, EventBus } from "App/IOC";
import { Users } from "App";

import SignIn from "Web/Views/User/Components/SignIn.vue";
import SignInActions from "Web/Views/User/Components/SignInActions.vue";
import User from "Web/Views/User/Index.vue";
import Main from "Web/Views/Main/Index.vue";

import NotFound from "Web/Views/NotFound.vue";
import UnderConstruction from "Web/Views/UnderConstruction.vue";

import Products from "Web/Views/Products/Index.vue";
import Product from "Web/Views/Products/Single/Index.vue";
import Customers from "Web/Views/Customers/Index.vue";
import Customer from "Web/Views/Customers/Single/Index.vue";
import AdsPublications from "Web/Views/AdsPublications/Index.vue";
import Subscriptions from "Web/Views/Subscriptions/Index.vue";
import Subscription from "Web/Views/Subscriptions/Single/Index.vue";

/* Configuration. */
import GlobalConfiguration from "Web/Views/Main/Configuration/Index.vue";
import GeneralGlobalConfiguration from "Web/Views/Main/Configuration/General.vue";
import Coupons from "Web/Views/Coupons/Index.vue";
import Coupon from "Web/Views/Coupons/Single/Index.vue";
import PriceTargets from "Web/Views/PriceTargets/Index.vue";
import PriceTarget from "Web/Views/PriceTargets/Single/Index.vue";

/* If this flag becomes true, and the user tries to navigate away to another
   route, the system will show a confirmation popup to protect against
   accidental data loss (Unsaved changes). */
let showDataLossGuard: boolean = false;

Vue.use( Router );

/* To avoid an issue with $t being empty because it has yet to be injected to IOC because if
   we put this instantiation at the module level here, it will get executed during
   node module importation step, which is before we inject the actual $t() implementation into
   our IOC (Through Web/Main.ts), so we wrap it in a funtion. */
function Get(): Router {
    EventBus.Subscribe( "LockMenuActions", ( value: boolean ): void => {
        showDataLossGuard = value;
    });

    const router = new Router({
        mode: "history",
        base: process.env.BASE_URL,
        routes: [
            {
                path: "/user",
                component: User,
                children: [
                    {
                        path: "",
                        redirect: "signin"
                    },

                    {
                        path: "signin",
                        name: "signin",
                        components: {
                            default: SignIn,
                            actions: SignInActions
                        }
                    }
                ]
            },

            {
                path: "/",
                component: Main,
                children: [
                    {
                        path: "",
                        component: UnderConstruction
                    },

                    {
                        path: "under-construction",
                        component: UnderConstruction
                    },

                    {
                        path: "products",
                        component: Products
                    },

                    {
                        path: "products/:id(\\d+)",
                        name: "product",
                        component: Product
                    },

                    {
                        path: "coupons",
                        name: "coupons",
                        component: Coupons
                    },

                    {
                        path: "coupons/:id(\\d+)",
                        name: "coupon",
                        component: Coupon
                    },

                    {
                        path: "price-targets",
                        name: "price-targets",
                        component: PriceTargets
                    },

                    {
                        path: "price-targets/:id(\\d+)",
                        name: "price-target",
                        component: PriceTarget
                    },

                    {
                        path: "ads-publications",
                        component: AdsPublications
                    },

                    {
                        path: "customers",
                        component: Customers
                    },

                    {
                        path: "customers/:id(\\d+)",
                        name: "customer",
                        component: Customer
                    },

                    {
                        path: "subscriptions",
                        component: Subscriptions
                    },

                    {
                        path: "subscriptions/:id(\\d+)",
                        name: "subscription",
                        component: Subscription
                    },

                    {
                        path: "configuration",
                        component: GlobalConfiguration,
                        children: [
                            {
                                path: "",
                                redirect: "general"
                            },

                            {
                                path: "general",
                                component: GeneralGlobalConfiguration
                            }
                        ]
                    }
                ]
            },

            {
                path: "*",
                component: NotFound
            }
        ]
    });

    /* Authentication guards. */
    router.beforeEach( async (
        to: Route,
        from: Route,
        next: ( to?: RawLocation ) => void
    ): Promise<void> => {
        /* This goes through the matched routes from last to first, finding the closest route with a
           title. Eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles,
           nested's will be chosen. */
        const nearestWithTitle = to.matched.slice().reverse().find(
            ( r ): void => r.meta && r.meta.title
        );

        if( nearestWithTitle )
            document.title = nearestWithTitle.meta.title;

        const authenticated = await Users.CheckAuthentication() === undefined;

        const publicRoutes: string[] = [
            "/user/signin"
        ];

        const isPublicRoute = publicRoutes.includes( to.matched[ to.matched.length - 1 ].path );

        if( !isPublicRoute && !authenticated ) {
            next( { path: "/user/signin" } );
            return;

        } else if( isPublicRoute && authenticated ) {
            next( { path: "/" } );
            return;
        }

        if( showDataLossGuard ) {
            EventBus.Publish( "Confirmation", {
                Props: {
                    Message: $t( "GLOBAL_dataLossGuard" ),

                    Actions: [{
                        Id: "continue",
                        Name: $t( "GLOBAL_dataLossGuardContinue" ),
                        Active: true
                    }]
                },

                Events: {
                    Action: (): void => {
                        showDataLossGuard = false;
                        next();
                    }
                }
            });

            return;
        }

        next();
    });

    return router;
}

export default {
    Get
};
