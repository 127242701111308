


import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import BooleanField from "Web/Components/Common/Input/Boolean.vue";
import Form from "Web/Components/Form/Index.vue";
import FormField from "Web/Components/Form/Field.vue";
import PopupWindow from "Web/Components/Popup/Window.vue";
import FormFieldset from "Web/Components/Form/Fieldset.vue";

import { AdsPublications } from "App";

import { $t, EventBus } from "App/IOC";

import {
    CustomNotification,
    Field
} from "App/Models";

import {
    Activated,
    Dictionary,
    Identifiable,
    Named
} from "App/Contracts";

@Component({
    components: {
        BooleanField,
        Field: FormField,
        Fieldset: FormFieldset,
        Form,
        PopupWindow
    }
})
export default class GatewayFtpForm extends Vue {
    @Prop() readonly row?: any;

    @Ref( "form" )
    private readonly form?: Form;

    private action: Identifiable & Named & Activated = {
        Id: "create",
        Name: this.row !== undefined ? $t( "ADS_PUBLICATION_confirmUpdate" ) 
            : $t( "ADS_PUBLICATION_confirmCreate" ),
        Active: true
    };

    private fields: Dictionary<Field> = {};

    private record: any = {};

    private async beforeCreate(): Promise<void> {
        this.fields = await AdsPublications.Fields.Get();
    }

    private async onFormSubmit(): Promise<void> {
        if( this.form === undefined )
            return;

        this.action.Active = false;

        const record: any | undefined = this.form.GetRecord() as any;

        if(this.row !== undefined && this.record !== undefined 
            && this.row !== null && this.row.Id !== undefined) {
            await this.update(record);   
        }
        else {
            await this.create(record);
        }
    }

    private async create(record: any): Promise<void> {
                
        const [ createdRecord, error ] = await AdsPublications.Create( record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.action.Active = true;
            this.canCreate();

            return;
        }

        EventBus.Publish(
            "Notification",
            CustomNotification.Success(
                $t( "ADS_PUBLICATION_createSuccessTitle" ),
                $t( "ADS_PUBLICATION_createSuccessMessage" )
            )
        );

        this.$emit( "Create", createdRecord );
    }

    private async update(record: any): Promise<void> {

        const [ createdRecord, error ] = await AdsPublications.Update( this.record, record );

        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.action.Active = true;
            this.canCreate();

            return;
        }

        EventBus.Publish(
            "Notification",
            CustomNotification.Success(
                $t( "ADS_PUBLICATION_createSuccessTitle" ),
                $t( "ADS_PUBLICATION_createUpdateMessage" )
            )
        );

        this.$emit( "Create", createdRecord );
    }

    private canCreate(): void {
        if( this.form === undefined )
            return;

        const record = this.form.GetRecord() as any;
        this.action.Active = record.Name !== undefined && record.Host !== undefined;
    }

    private async getGatewayFormatOptions(): Promise<[any[], undefined]> {
        const result = AdsPublications.PlaceholderData.getGatewayFormatOptions();
        return [ result, undefined ];
    }

    private async getEncodingOptions(): Promise<[any[], undefined]> {
        const result = AdsPublications.PlaceholderData.getEncodingOptions();
        return [ result, undefined ];

    }

    @Watch( "row", { immediate: true } )
    private async onRowChanged(): Promise<void> {
        if( this.row === undefined || this.row === null || this.row === "" ) return;
        const id = this.row.Id ? this.row.Id : this.row.id; 
        const [ record, error ] = await AdsPublications.Get( id );
        if( error !== undefined ) {
            EventBus.Publish( "Error", error );
            this.action.Active = true;
            this.canCreate();

            return;
        }

        const formatedRecord = this.capitalizeKeys( record );

        if(formatedRecord.Distant_folder !== undefined) {
            formatedRecord.DistantFolder = formatedRecord.Distant_folder;
            delete formatedRecord.Distant_folder;
        }

        if(formatedRecord.Password !== undefined) {
            formatedRecord.Password = {
                Value: formatedRecord.Password
            };
        }

        if(formatedRecord.Serializer !== undefined) {
            formatedRecord.SerializerId = 
                formatedRecord.Serializer ? formatedRecord.Serializer.id : undefined;
        }
        if(formatedRecord.Format !== undefined) {
            const formatOptions = AdsPublications.PlaceholderData.getGatewayFormatOptions();
            if( formatOptions !== undefined ) {
                formatedRecord.Format = 
                    formatOptions.find( x => x.Id === formatedRecord.Format );
            }
        }

        if(formatedRecord.Encoding !== undefined) {
            const encodingOptions = AdsPublications.PlaceholderData.getEncodingOptions();
            if( encodingOptions !== undefined ) {
                formatedRecord.Encoding = 
                    encodingOptions.find( x => x.Id === formatedRecord.Encoding );
            }
        }
        this.record = formatedRecord;
    }

    private capitalizeKeys(obj: any): Record<string, any> {
        const newObj: Record<string, any> = {};
        for(const key in obj) {
            if(obj.hasOwnProperty(key)) {
                const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                newObj[capitalizedKey] = obj[key];
            }
        }
        return newObj;
    }
}
