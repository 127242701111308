/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

// import { Customer } from "App/Models";
import { AdsPublications } from "App/IOC/Services";


import Columns from "./Columns";
import Fields from "./Fields";
import Filters from "./Filters";
import PlaceholderData from "./PlaceholderData";

export default {
    Columns,
    Filters,
    Fields,
    PlaceholderData,
    Create: AdsPublications.Create,
    Update: AdsPublications.Update,
    Get: AdsPublications.Get,
    Find: AdsPublications.Find
};
