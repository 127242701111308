/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */

import { $t } from "App/IOC";

function getGatewayFormatOptions(): any[] {
    return [
        {
            Id: "zip",
            Name: $t("ADS_PUBLICATION_statusZip"),
            ShortName: $t("ADS_PUBLICATION_statusZip")
        },
        {
            Id: "text",
            Name: $t("ADS_PUBLICATION_statusText"),
            ShortName: $t("ADS_PUBLICATION_statusText")
        }
    ]
}

function getEncodingOptions(): any[] {
    return [
        {
            Id: "utf-8",
            Name: $t("ADS_PUBLICATION_statusUtf8")
        },
        {
            Id: "latin",
            Name: $t("ADS_PUBLICATION_statusLatin")
        }
    ]
}

export default {
    getGatewayFormatOptions,
    getEncodingOptions
}
