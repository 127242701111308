/* © Facilogi. See COPYRIGHT file for full copyright & licensing details. */
import { Filter } from "@Internal/Contracts";
import { TextFilter } from "@Internal/Models";

let defaultFilters: Filter[] = [];

function Get(): Filter[] {
    if( defaultFilters.length === 0 )
        defaultFilters = [
            new TextFilter(
                "name",
                $t( "ADS_PUBLICATION_columnName" ),
                $t( "ADS_PUBLICATION_columnName" )
            )
        ];

    return defaultFilters;
}

export default {
    Get
};
