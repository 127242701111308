/* © Facilogi. See LICENSE file for full copyright & licensing details. */

import { Dictionary } from "@Internal/Contracts";
import { Field } from "@Internal/Models";
import { $t } from "App/IOC";

let defaultFields: Dictionary<Field> = {};

async function Get(): Promise<Dictionary<Field>> {
    await Promise.resolve();

    if(Object.keys(defaultFields).length === 0)
        defaultFields = {
            Name: Field.Text(
                "Name",
                $t("ADS_PUBLICATION_fieldLabel")
            ),
            Description: Field.MultiLineText(
                "Description",
                $t("ADS_PUBLICATION_fieldDescription")
            ),
            Host: Field.Text("Host", $t("ADS_PUBLICATION_fieldFtpHost")),
            Username: Field.Text("Username", $t("ADS_PUBLICATION_fieldFtpUsername")),
            Password: Field.Text("Password", $t("ADS_PUBLICATION_fieldFtpPassword")),
            DistantFolder: Field.Text("DistantFolder",
                $t("ADS_PUBLICATION_fieldFtpRemoteDirectory")),
            Port: Field.Text("Port", $t("ADS_PUBLICATION_fieldFtpPort")),
            SerializerId: Field.Number("SerializerId",
                $t("ADS_PUBLICATION_fieldSiteIdentifier")),
            Format: Field.Selection(
                "Format",
                $t("ADS_PUBLICATION_fieldExportFormat")
            ),
            Encoding: Field.Selection(
                "Encoding",
                $t("ADS_PUBLICATION_fieldEncoding")
            )

            // ExportType: Field.Selection(
            //     "ExportType",
            //     $t("ADS_PUBLICATION_fieldExportType")
            // ),
            // Logo: Field.Text("Logo", $t("ADS_PUBLICATION_fieldLogo")),
            // Quota: Field.Text("Quota", $t("ADS_PUBLICATION_fieldQuota")),

            // FtpMode: Field.Text("FtpMode", $t("ADS_PUBLICATION_fieldFtpMode")),
            // FtpModeSsl: Field.Text("FtpModeSsl", $t("ADS_PUBLICATION_fieldFtpModeSsl"))
        };

    return defaultFields;
}

export default {
    Get
};
